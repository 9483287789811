<template>
    <AboutScreen msg="Welcome to Your Vue.js App"/>
</template>

<script>
  
import AboutScreen from '@/components/AboutScreen.vue'

export default {
  name: 'About',
  components: {
    AboutScreen
  }
}
</script>
